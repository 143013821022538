export default function useChat() {
  const resolveAvatarBadgeVariant = status => {
    if (status === 1) return 'success'
    return 'secondary'
  }

  return {
    resolveAvatarBadgeVariant,
  }
}
