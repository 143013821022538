<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          shallShowUserProfileSidebar =
          false
      "
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          เริ่มการสนทนา
          <!-- startConversation -->
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 badge-minimal"
                badge
                :badge-variant="
                  resolveAvatarBadgeVariant(activeChat.contact.status)
                "
              />
              <h6 class="mb-0">
                {{ activeChat.contact.fullName }}
              </h6>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          style="position: relative; z-index: 1"
          @submit.prevent="sendMessage"
        >
          <div
            v-if="uploadedImageUrl.length"
            :class="{
              'show-file tabImg-big d-flex justify-content-left table-container':
                uploadedImageUrl.length > 9,
              'show-file tabImg-normal d-flex justify-content-left':
                uploadedImageUrl.length <= 9,
            }"
          >
            <div class="mt-1 d-flex justify-content-left ml-1">
              <div
                v-for="(item, index) in uploadedImageUrl"
                :key="index"
                class="box-img ml-1"
              >
                <img
                  :src="item"
                  alt="Uploaded Image"
                >
                <feather-icon
                  icon="Trash2Icon"
                  size="21"
                  class="cursor-pointer text-danger"
                  @click="removeImage(index)"
                />
              </div>
            </div>
          </div>

          <feather-icon
            icon="CameraIcon"
            size="21"
            class="mr-1 cursor-pointer"
            @click="addFilesImg"
          />

          <!-- <feather-icon
            icon="PaperclipIcon"
            size="21"
            class="mr-1 cursor-pointer"
            @click="addFiles"
          /> -->

          <input
            ref="fileInputImg"
            type="file"
            style="display: none"
            accept="image/*"
            multiple
            @change="handleImageUpload"
          >
          <!-- <input
            ref="fileInput"
            type="file"
            style="display: none;"
            @change="handleFileUpload"
          > -->

          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="พิมพ์ข้อความ..."
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            {{ $t('key-236') }}
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="
        shallShowActiveChatContactSidebar
      "
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.id : null
        "
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
    <!-- @open-chat -->
  </div>
</template>

<script>
import store from '@/store'
import {
  ref,
  onUnmounted,
  nextTick,
  computed,
  onMounted,
} from '@vue/composition-api'
import {
  BAvatar,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import moment from 'moment-timezone'
import axios from '@axios'
import { required } from '@validations'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {
    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  computed: {
    // WsChatAdmin() {
    //   return this.$store.state.app.WsChatAdmin
    // },
  },
  setup() {
    const fileInputImg = ref(null)
    const fileInput = ref(null)
    const uploadedImageUrl = ref([])
    const uploadedFileName = ref(null)
    const WsChatInstance = ref(null)
    const CartLength = ref(0)
    // const BaseWsUrl = computed(() => store.state.app.BaseWsUrl)
    const WsMain = computed(() => store.state.app.WsMain) // เช็คด้วยว่าเชื่อมต่อกับ Ws หรือยัง
    const UserData = JSON.parse(localStorage.getItem('userData'))
    const WsChat = computed(() => store.state.app.WsChatAdmin) // เช็คด้วยว่าเชื่อมต่อกับ Ws หรือยัง
    // const profileadmin = computed(() => store.state.app.WsChatProfile)
    const clickCount = ref(0)
    const addFilesImg = () => {
      fileInputImg.value.click()
    }
    const addFiles = () => {
      fileInput.value.click()
    }

    const handleImageUpload = event => {
      const file = event.target.files
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < file.length; i++) {
        const filepath = file[i]

        const reader = new FileReader()
        reader.onload = e => {
          uploadedImageUrl.value.push(e.target.result)
          // console.debug(uploadedImageUrl.value)
        }
        reader.readAsDataURL(filepath)
      }

      // if (file && file.type.startsWith('image/')) {
      //   uploadedImageUrl.value = URL.createObjectURL(file)
      //   console.debug(uploadedImageUrl.value)
      // }
    }

    const handleFileUpload = event => {
      const file = event.target.files[0]
      if (file) {
        uploadedFileName.value = file.name
        console.log(uploadedFileName.value)
      }
    }

    const removeImage = index => {
      uploadedImageUrl.value.splice(index, 1)
    }

    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    const profileUser = ref({
      id: 0,
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/anan-img/logo/lg.png'),
      // / assets / images / avatars / 8.png
      fullName: 'John Doe',
      role: 'admin',
      about:
        'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
      status: 'online',
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false,
      },
    })
    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) { store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) { store.unregisterModule(CHAT_APP_STORE_MODULE_NAME) }
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }
    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------

    const chatsContacts = ref([])
    // const chatsContactsnew = ref([])
    const contacts = ref([])
    const profileUserDataMinimal = ref({})
    const fetchChatAndContacts = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.anan-cargo.com/api/Chat/getData',
        // url: 'http://127.0.0.1:4444/api/Chat/getData',
        headers: {
          Authorization: `Bearer ${UserData.token}`, // Token จาก localStorage
        },
      }
      try {
        const { data: res } = await axios.request(config)
        chatsContacts.value = res.chatsContacts
        contacts.value = res.contacts
        profileUserDataMinimal.value = profileUser.value
        store.commit('app/SET_WSCHATPROFILE', profileUserDataMinimal.value)
        // console.debug(WsMain.value)
        // console.debug(contacts.value)
        chatsContacts.value.forEach(ele => {
          if (!ele.ws) {
            // eslint-disable-next-line no-param-reassign
            ele.ws = WsMain.value.subscribe(ele.room)
          }
          // CheckWsChat.value.push(ele.ws)
        })
        WsChatInstance.value = WsMain.value.subscribe(
          `fristchat:${UserData.agent_id}`,
        )
        if (WsChatInstance.value) {
          // เช็คว่าเชื่อมต่อกก่อนsubscribe
          WsChatInstance.value.on('open', () => {
            console.log('WsOnChat established')
          })
          WsChatInstance.value.on('message', message => {
            if (message) {
              const CheckData = chatsContacts.value.findIndex(
                ele => ele.id === message.id,
              )
              if (CheckData < 0) {
                if (
                  !message.chat.lastMessage.message
                  && message.chat.lastMessage.fileimg
                ) {
                  // eslint-disable-next-line no-param-reassign
                  message.chat.lastMessage.message = `${message.fullName} ส่งรูปภาพ`
                }
                chatsContacts.value.push(message)
                contacts.value.push(message)
                chatsContacts.value.forEach(ele => {
                  if (!ele.ws) {
                    // eslint-disable-next-line no-param-reassign
                    ele.ws = WsMain.value.subscribe(ele.room)
                  }
                })
                // console.debug(message)
                // store.commit('app/SET_WSCHATADMIN', chatsContacts.value)
              } else {
                contacts.value.forEach(ele => {
                  if (ele.id === message.id) {
                    // eslint-disable-next-line no-param-reassign
                    ele.status = message.status
                  }
                })
                chatsContacts.value.forEach(ele => {
                  if (ele.id === message.id) {
                    // eslint-disable-next-line no-param-reassign
                    ele.status = message.status

                    if (message.chat.lastMessage) {
                      // eslint-disable-next-line no-param-reassign
                      ele.chat.lastMessage = message.chat.lastMessage
                      // eslint-disable-next-line operator-assignment, no-param-reassign
                      ele.chat.unseenMsgs = message.chat.unseenMsgs
                    }
                  }
                })
              }
            }
          })
          WsChatInstance.value.on('error', error => {
            console.debug(error)
          })
        }
        store.commit('app/SET_WSCHATADMIN', chatsContacts.value)
      } catch (error) {
        console.log(error)
        // การจัดการข้อผิดพลาด เช่น แสดงข้อความผิดพลาดหรือปรับปรุงสถานะเมื่อเกิดข้อผิดพลาด
      }
      // store.dispatch('app-chat/fetchChatsAndContacts')
      //   .then(response => {
      //   // console.debug(JSON.stringify(response.data))
      //     console.debug(response.data)
      //     // console.debug('fetchChatAndContacts', response.data)
      //     chatsContacts.value = response.data.chatsContacts
      //     // chatsContacts.value[0].test = 'kjujuj'
      //     contacts.value = response.data.contacts
      //     // console.debug(chatsContacts.value)
      //     // eslint-disable-next-line no-use-before-define
      //     // console.debug(contacts.value)
      //     profileUserDataMinimal.value = response.data.profileUser
      //     chatsContacts.value.forEach(ele => {
      //     // eslint-disable-next-line no-param-reassign
      //       ele.ws = WsMain.value.subscribe(`chat:${ele.id}`)
      //       //   // WsChatInstance.value =
      //     })
      //   })
    }
    const checkWsConnect = async () => {
      if (!WsMain.value) {
        await fetchChatAndContacts()
      }
      if (!WsChat.value) {
        await fetchChatAndContacts()
      } else {
        chatsContacts.value = WsChat.value
        chatsContacts.value.sort((a, b) => new Date(a.time) - new Date(b.time))
        profileUserDataMinimal.value = profileUser.value
      }
    }
    // fetchChatAndContacts()
    onMounted(() => {
      checkWsConnect()
      // fetchChatAndContacts()
      // เรียกใช้งาน function เมื่อ component ถูก mount
    })

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref('')
    const chatInputMessage = ref('')
    const contactone = ref(null)
    const openChatOfContact = async data => {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.anan-cargo.com/api/Chat/getChatChannel',
        // url: 'http://127.0.0.1:4444/api/Chat/getChatChannel',
        headers: {
          Authorization: `Bearer ${UserData.token}`,
        },
      }
      try {
        const item = {
          chat: {
            chat: null,
            id: 1,
            unseenMsgs: 0,
            userId: data.id,
          },
          contact: {
            avatar: data.avatar,
            fullName: data.fullName,
            id: data.id,
            status: data.status,
            room: data.room,
          },
        }
        const Obj = {
          fullName: data.fullName,
          id: data.id,
          status: data.status,
          room: data.room,
        }
        contactone.value = null
        const response = await axios.request({ ...config, data: Obj })
        item.chat.chat = response.data
        // console.debug(chatsContacts.value)
        // chatsContacts.value.forEach(ele => {
        //   if (ele.id === data.id) {
        //     if (!ele.ws) {
        //       // eslint-disable-next-line no-param-reassign
        //       ele.ws = WsMain.value.subscribe(ele.room)
        //     }
        //   }
        // })
        contactone.value = chatsContacts.value.find(c => c.id === data.id)
        contactone.value.chat.unseenMsgs = 0
        if (contactone.value.ws) {
          contactone.value.ws.on('open', () => {
            console.debug('WsOnChat established')
          })
          contactone.value.ws.on('message', message => {
            if (!message.autochat) {
              item.contact.status = message.status
              // console.debug(message.unseenMsgs)
              // const obj = {
              //   message: message.message,
              //   senderId: message.senderId,
              //   time: message.time,
              // }
              // if (message.fileimg.length) {
              //   obj.fileimg = message.fileimg
              // }
              // console.debug(contactone)
              item.chat.chat.push(message)
              if (contactone.value.id === message.senderId) {
                contactone.value.chat.unseenMsgs = message.unseenMsgs
                if (message.fileimg.length && !message.message) {
                  if (UserData.userID === message.senderId) {
                    const objnews = {
                      message: 'คุณส่งรูปภาพ',
                      senderId: message.senderId,
                      time: message.time,
                    }
                    contactone.value.chat.lastMessage = objnews
                    contactone.value.chat.unseenMsgs = message.unseenMsgs
                  } else {
                    const objnew = {
                      message: `${message.fullName} ส่งรูปภาพ`,
                      senderId: message.senderId,
                      time: message.time,
                    }
                    contactone.value.chat.lastMessage = objnew
                    contactone.value.chat.unseenMsgs = message.unseenMsgs
                  }
                } else {
                  contactone.value.chat.lastMessage = message
                  contactone.value.chat.unseenMsgs = message.unseenMsgs
                }
              }

              // activeChat.value = item
              // contactone.value.chat.lastMessage = obj
              nextTick(() => {
                scrollToBottomInChatLog()
              })
            }

            // this.chatData.chat.chat.push(message)
          })
          contactone.value.ws.on('error', error => {
            console.debug('WsOnChat Error', error)
          })
        }
        activeChat.value = item

        // console.debug(contactone.value)

        nextTick(() => {
          scrollToBottomInChatLog()
        })
      } catch (err) {
        console.log(err)
      }
      // const obj = {
      //   avatar: data.avatar,
      //   fullName: data.fullName,
      //   id: data.id,
      //   status: data.status,
      // }
      // Reset send message input value
      // chatInputMessage.value = null
      // activeChat.value = ''
      // contactone.value = null

      // store.dispatch('app-chat/getChat', { userId: data.id })
      //   .then(response => {
      //     console.debug(response.data)
      //     activeChat.value = response.data
      //     contactone.value = chatsContacts.value.find(c => c.id === userId)
      //     if (contactone.value.ws) {
      //       //
      //       contactone.value.ws.on('open', () => {
      //         console.debug('WsOnChat established')
      //       })
      //       // this.WsChat.emit('cart', '')
      //       contactone.value.ws.on('message', message => {
      //         const obj = {
      //           message: message.message,
      //           senderId: message.senderId,
      //           time: message.time,
      //         }
      //         if (message.img) {
      //           obj.img = message.img
      //         }
      //         contactone.value.chat.lastMessage = obj
      //         response.data.chat.chat.push(obj)
      //         nextTick(() => { scrollToBottomInChatLog() })

      //         // this.chatData.chat.chat.push(message)
      //       })
      //       // contact.ws.on('close', event => {
      //       //   console.log('Connection closed', event)
      // })
      //       contactone.value.ws.on('error', error => {
      //         console.debug('WsOnChat Error', error)
      //       })
      //       activeChat.value = response.data
      //       nextTick(() => { scrollToBottomInChatLog() })
      //     }

      //     // if (chatsContacts.value === userId) {

      //     // }

      //     // SubWsOnChat(userId)
      //     // console.log('response', response.data)
      //     //

      //     // // Set unseenMsgs to 0
      //     // const contact = chatsContacts.value.find(c => c.id === userId)
      //     // if (contact) contact.chat.unseenMsgs = 0
      //     // eslint-disable-next-line no-use-before-define
      //     // Scroll to bottom
      //     // nextTick(() => { scrollToBottomInChatLog() })
      // })

      //   // if SM device =>  Close Chat & Contacts left sidebar
      //   .catch(error => {
      //     console.log(error)
      //     activeChat.value = null
      //     contactone.value = null
      //   })
      //   .finally(() => {
      //     // eslint-disable-next-line no-use-before-define
      //     mqShallShowLeftSidebar.value = false
      //   })
      // console.log(WsChat)
    }
    // const getData = () => {
    //   this.$http.get('/Chat/getData')
    //     .then(response => {
    //       console.debug(response)
    //       // profileUserData.value = response.data
    //       // shallShowUserProfileSidebar.value = true
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       // การจัดการข้อผิดพลาด (เช่นแสดงข้อความผิดพลาด)
    //     })
    // }
    const sendMessage = () => {
      if (!chatInputMessage.value && !uploadedImageUrl.value.length) return
      // console.debug(activeChat.value)
      // const payload = {
      //   contactId: activeChat.value.contact.id,
      //   // eslint-disable-next-line no-use-before-define
      //   senderId: profileUserDataMinimal.value.id,
      //   message: chatInputMessage.value,
      // }

      const msg = {
        msgType: chatInputMessage.value ? 'text' : 'image',
        message: chatInputMessage.value,
        time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        senderId: UserData.userID,
        unseenMsgs: true,
      }
      // const WsChatInstance = WsMain.value.subscribe(`chat:${activeChat.value.contact.id}`)
      // console.debug(WsChatInstance)

      // WsChatInstance.on('error', error => {
      //   console.log('Connection Error', error)
      // })

      // store.dispatch('app-chat/sendMessage', msg)
      //   .then(response => {
      //     //     console.debug(response.data)
      //     const { newMessageData } = response.data
      if (uploadedImageUrl.value.length) {
        msg.fileimg = uploadedImageUrl.value
      }
      if (msg.msgType === 'text') {
        msg.message = chatInputMessage.value
      }
      // console.debug(uploadedImageUrl.value)
      // ? If it's not undefined => New chat is created (Contact is not in list of chats)
      // if (chat !== undefined) {
      //   activeChat.value = { chat, contact: activeChat.value.contact }

      //   chatsContacts.value.push({
      //     ...activeChat.value.contact,
      //     chat: {
      //       id: chat.id,
      //       lastMessage: newMessageData,
      //       unseenMsgs: 0,
      //     },
      //   })

      // WsMain.emit('message', newMessageData)
      // } else {
      // Add message to log
      // activeChat.value.chat.chat.push(newMessageData)
      // console.debug(activeChat)
      const contact = chatsContacts.value.find(
        c => c.id === activeChat.value.contact.id,
      )
      // if (contact) {
      //   contact.ws.emit('message', msg)
      // }

      // WsChatInstance.value.emit('message', msg)
      // WsMain.emit('message', newMessageData)
      // }
      // Reset send message input value
      chatInputMessage.value = ''
      uploadedImageUrl.value = []
      // console.debug(contactone.value)
      // console.debug(msg)
      // Set Last Message for active contact
      // const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
      contact.ws.emit('message', msg)
      // console.debug(newMessageData)
      // console.debug(contact.chat)
      //

      // Scroll to bottom
      nextTick(() => {
        scrollToBottomInChatLog()
      })
      // })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status

    const shallShowUserProfileSidebar = ref(false)
    // const showUserProfileSidebar = () => profileUserDataMinimal.value
    // shallShowUserProfileSidebar.value = true
    // store.dispatch('app-chat/getProfileUser')
    // const config = {
    //   method: 'get',
    //   maxBodyLength: Infinity,
    //   url: 'http://127.0.0.1:4444/api/Chat/getDataAmin',
    //   headers: {
    //     Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjc1LCJpYXQiOjE3MDA2MzY4ODl9.trkW6U11gepIz-9rvATngWUWO4PxdvIHPqbutt1GpVI',
    //   },
    // }
    // try {
    //   const { data: res } = await axios.request(config)
    //   // console.debug(res.data)
    //   profileUserDataMinimal.value = res.data
    //   // console.debug(profileUserDataMinimal.value)
    //   // shallShowUserProfileSidebar.value = true
    // } catch (err) {
    //   console.log(err)
    // }
    //   .then(response => {
    //
    //   })

    // showUserProfileSidebar()
    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      profileUser,
      CartLength,
      addFilesImg,
      addFiles,
      fileInputImg,
      fileInput,
      uploadedImageUrl,
      handleImageUpload,
      handleFileUpload,
      // WsChatInstance,
      contactone,
      // Filters
      // formatDate,
      removeImage,
      // config,
      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,
      // Single Chat
      refChatLogPS,
      activeChat,
      clickCount,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      required,
      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      // showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      // getData,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
